import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout';
import { gsap, Circ, Power4, Linear } from 'gsap';

import { ParagraphTitle, Paragraph } from '../components/paragraph';
import ParagraphSection from '../components/paragraphSection';
import johannaBrun3Wide from '../images/johanna_brun3_wide.jpg';
import johannaBrun4 from '../images/johanna_brun4.jpg';
import johannaBrun1 from '../images/johanna_brun1.jpeg';
import favicon from '../favicon.png';

import j1 from '../images/14.jpg';

const AboutPage = () => {
  const [showAbout, setShowAbout] = useState(false);
  const [showContact, setShowContact] = useState(false);

  const pageTextRefs = useRef([]);

  const fadeInHero = () => {
    gsap
      .to('.pageTitle', { opacity: 1, ease: 'power1.in', duration: 1.8 })
      .delay(0.5);

    gsap
      .to('.heroImageWrapper', {
        opacity: 1,
        transform: 'translateY(-10px)',
        duration: 4,
        ease: 'power1.out',
      })
      .delay(0.5);
  };

  const fadeInParagraphs = (element) => {
    gsap.to(element, { opacity: 1, duration: 2.5, ease: Power4.easeOut });
  };

  useEffect(() => {
    fadeInHero();
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            fadeInParagraphs(entry.target);
          }
        });
      },
      { threshold: 0.5 } // Adjust threshold value as needed
    );

    pageTextRefs.current.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => {
      pageTextRefs.current.forEach((ref) => {
        if (ref) observer.unobserve(ref);
      });
    };
  }, []);

  const aboutSection = {
    margin: 'auto',
    width: '45vw',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  };

  return (
    <Layout
      showAbout={showAbout}
      setShowAbout={setShowAbout}
      showContact={showContact}
      setShowContact={setShowContact}
    >
      <Helmet
        title={'ABOUT - JOHANNA BRUN | Swedish Artist, Producer & Songwriter'}
        htmlAttributes={{
          lang: 'en',
        }}
      >
        <meta
          name="description"
          content={
            "Read about Johanna Brun, an artist and songwriter based in Stockholm. Currently crafting her debut album—a heartfelt blend of personal experiences and nature's inspiration."
          }
        />
        <link rel="icon" href={favicon} />
      </Helmet>

      <section className="aboutSection" style={aboutSection}>
        <h1 className="pageTitle">
          Johanna
          <br />
          Brun
        </h1>
        <div
          className="heroImageWrapper"
          style={{
            transform: 'translateY(20px)',
            opacity: 0,
            zIndex: -1,
            height: 550,
            width: 400,
            margin: 'auto',
            position: 'relative',
            borderRadius: 35,
            overflow: 'hidden',
          }}
        >
          <img
            style={{
              height: '100%',
              width: '100%',
              objectFit: 'cover',
            }}
            src={j1}
            alt={'Johanna Brun'}
            draggable="false"
          />
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              background:
                'linear-gradient(to top, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.2) 100%)',
            }}
          />
        </div>
        <br />
        <br />
        <h2 className="pageSubtitle">
          Artist and songwriter based in Stockholm, currently crafting my debut
          album—a heartfelt blend of personal experiences and nature's
          inspiration.
        </h2>
        <p className="pageText" ref={(el) => (pageTextRefs.current[1] = el)}>
          I am Johanna, a dynamic artist, producer and songwriter originally
          from Strängnäs, Sweden, currently based and working in Stockholm where
          I live and bring my dreams to life. Alongside my studies at the Royal
          College of Music, I create, compose, produce and arrange music in the
          heart of the city - for projects, other artists, and most importantly,
          for myself. Here, I have the opportunity to explore my musicality,
          form connections and collaborations that inspire me. In addition to
          the above, I freelance as a singer, pianist and singer/songwriter. I
          am also involved in the project{' '}
          <a
            style={{ textDecoration: 'underline' }}
            href="https://scenkonstsormland.se/musik/rostratt/"
            rel="noopener noreferrer nofollow"
            target="_blank"
          >
            Rösträtt, sång på förskolan
          </a>
          , with the goal of inspiring more singing in preschools across the
          country.
          <br />
          <br />
          Music has been a lifelong passion for me, from the moments I spent in
          my father's studio as a child. Right now, I am working intensively to
          complete my upcoming debut album - a heartfelt journey filled with
          emotion inspired by nature and my personal experiences. Through my
          music, I aim to share stories that hopefully inspire and resonate with
          others.
          <br />
          <br />I strongly believe in the power of music to deeply affect
          people, and I strive to create emotional connections through my
          artistry. With every note and every lyric, I aim to touch listeners
          and evoke emotions that go beyond what is audible.
        </p>
        <br />
        <br />
        <ParagraphTitle string={'//'} delay={0.5} fontSize={55} />
        <br />
        <br />
        <br />
        <p className="pageText" ref={(el) => (pageTextRefs.current[0] = el)}>
          Jag är Johanna, en mångsidig artist, producent och låtskrivare
          ursprungligen från Strängnäs och numera bosatt och verksam i Stockholm
          där jag lever och förverkligar mina drömmar. Parallellt med mina
          studier vid Kungliga Musikhögskolan skapar jag, komponerar, producerar
          och arrangerar musik i stadens hjärta - både för projekt, andra
          artister, men inte minst för mig själv. Här får jag chans till att
          utforska min musikalitet och bygga nätverk och samarbeten som
          inspirerar mig. Utöver ovanstående frilansar jag även som sångerska
          och pianist samt singer/songwriter. Jag är även verksam inom projektet{' '}
          <a
            style={{ textDecoration: 'underline' }}
            href="https://scenkonstsormland.se/musik/rostratt/"
            rel="noopener noreferrer nofollow"
            target="_blank"
          >
            Rösträtt, sång på förskolan
          </a>
          , med målet att inspirera till mer sång på förskolor runt om i landet.
          <br />
          <br />
          Musiken har varit en passion genom hela mitt liv, från de stunder jag
          satt i min pappas studio som barn. Just nu arbetar jag intensivt med
          att färdigställa mitt kommande debutalbum, en känslofylld resa som
          hämtar inspiration från naturen och mina personliga erfarenheter.
          Genom min musik vill jag dela med mig av berättelser som
          förhoppningsvis kan inspirera och resonera med andra.
          <br />
          <br />
          Jag tror starkt på musikens förmåga att påverka människor på djupet
          och strävar efter att skapa känslomässiga kopplingar genom min egen
          konstnärlighet. Med varje ton och varje textrad strävar jag efter att
          beröra lyssnare och väcka känslor som går bortom det hörbara.
        </p>
      </section>

      <ParagraphSection
        title="Press Feature"
        description="Discover the captivating review of Johanna Brun's latest song 'Bird', by David Bentley of the Nordic Music Central. The article explores the emotional journey and musical evolution of the song, highlighting its wonderful blend of strings and Johanna's emotive vocals."
        image={johannaBrun1}
        link={{
          external: true,
          string: 'Read the article',
          to: 'https://www.nordicmusiccentral.com/in-conversation-with-the-remarkable-johanna-brun-sweden/',
          newTab: true,
        }}
      />

      <ParagraphSection
        reverse
        title="Recent Conversation"
        description="Recently featured in an interview by Nordic Music Central, where Johanna shares her journey in the Swedish music scene. The piece delves into her upcoming song release, a prelude to her eagerly awaited debut album, and reflects on talent, perseverance, and overcoming challenges."
        image={johannaBrun3Wide}
        link={{
          external: true,
          string: 'Read the interview',
          to: 'https://www.nordicmusiccentral.com/in-conversation-with-the-remarkable-johanna-brun-sweden/',
          newTab: true,
        }}
      />
    </Layout>
  );
};

export default AboutPage;

export const Head = () => (
  <title>ABOUT - JOHANNA BRUN | Swedish Artist, Producer & Songwriter</title>
);
